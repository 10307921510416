import React from 'react'
import Layout from '../../components/Layout'
import SEO from "../../components/seo"

export default () => (

  <Layout>

    <SEO title="Thank you for contacting us!"
            />

    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you for contacting us!</h1>
          <p>We will respond to your request as soon as possible, usually within 24 hours.</p>
        </div>
      </div>
    </section>


    <script
      key={`body`}
      id="___gatsby"
        dangerouslySetInnerHTML={{
          __html: `

          <script>
            fbq('track', 'Contact');
          </script>


          `,
      }}
      />
  </Layout>
)
